<template>
    <header class="fixed w-full flex justify-between items-center py-4 px-6 bg-white z-50 top-0 left-0 max-w-2xl mx-auto inset-x-0">

      <a v-if="this.$route.name === 'Home'" ><img class="h-[34px]" :src="protocolos_semi"></a>

      <a v-else>
        <button type="button" class="w-[34px] h-[34px] relative inline-flex items-center justify-center text-white rounded-lg focus:outline-none bg-gradient-to-r from-principal to-secondary" @click="$router.go(-1)">

          <i class="text-sm fa-solid fa-chevron-left"></i>

        </button>
      </a>

      <div class="notifications">
        <router-link :to="{name: 'Recientes'}"  @click="this.notifications = 0"  class="w-[34px] h-[34px] relative flex justify-center items-center text-center text-white rounded-lg focus:outline-none bg-gradient-to-r from-principal to-secondary">

          <i class="text-lg fa-solid fa-bell"></i>

          <div v-if="notifications" class="bg-rojo absolute inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white  rounded-full -top-1 -end-1"></div>

        </router-link>
      </div>

    </header>


    <div class="px-6 bg-gradient-to-b from-white to-lightgray mt-[68px] pb-[85px] min-h-[calc(100vh-130px)] max-w-2xl mx-auto">
      <router-view></router-view>
    </div>


    <footer class="fixed bottom-0 left-0 z-50 w-full bg-white max-w-2xl mx-auto inset-x-0">

      <div v-if="deferredPrompt && iosPrompt != 1 && !this.getCookie('dismissInstallApp')" class="border-0 bg-black px-6 pb-6 pt-8 flex relative z-50">

        <div @click="dismiss" class="w-[34px] h-[34px] flex justify-center items-center text-center text-white rounded-lg outline-none bg-rojo absolute right-[10px] -top-4">

          <i class="text-lg fa-solid fa-xmark"></i>

        </div>

        <div>
          <img class="w-[58px] h-auto rounded-lg" :src="protocolos_semi_msg">
        </div>

        <div class="pl-4 -mt-1 ">
          <div class="text-[17px] text-white font-bold">
            INSTALA NUESTRA APLICACIÓN
          </div>

          <div class="font-medium text-[13px] text-white leading-4">
            Y accede de manera rápida a la APP.<br>
            ¡No ocupa espacio en tu dispositivo!
          </div>

          <button @click="install" class="outline-none rounded-lg mt-2 w-fit py-2 px-5 text-center bg-principal text-white text-[14px] font-bold">
            INSTALAR
          </button>
        </div>

      </div>

      <div class="flex justify-center h-full mx-auto font-medium pb-7 border-t border-midgray">
        <router-link :to="{name: 'Home'}" class="inline-flex flex-col items-center group w-20 mx-1" :class="{ active : this.$route.name == 'Home' }">
          <div class="h-1 w-full group-[.active]:bg-gradient-to-r from-principal to-secondary"></div>

          <i class="mt-2 group-[.active]:bg-gradient-to-r from-principal to-secondary inline-block text-darkgray group-[.active]:text-transparent group-[.active]:bg-clip-text text-xl fa-solid fa-house"></i>

          <span class="text-sm text-darkgray group-[.active]:text-black" >INICIO</span>

        </router-link>
        <router-link :to="{name: 'Busqueda'}" class="inline-flex flex-col items-center group w-20 mx-1" :class="{ active : this.$route.name == 'Busqueda'}">
          <div class="h-1 w-full group-[.active]:bg-gradient-to-r from-principal to-secondary"></div>

          <i class="mt-2 group-[.active]:bg-gradient-to-r from-principal to-secondary inline-block text-darkgray group-[.active]:text-transparent group-[.active]:bg-clip-text text-xl fa-solid fa-sliders"></i>

          <span class="text-sm text-darkgray group-[.active]:text-black" >FILTRAR</span>

        </router-link>
        <router-link :to="{name: 'Contacto'}" class="inline-flex flex-col items-center group w-20 mx-1"  :class="{ active : this.$route.name == 'Contacto' }">
          <div class="h-1 w-full group-[.active]:bg-gradient-to-r from-principal to-secondary"></div>

          <i class="mt-2 group-[.active]:bg-gradient-to-r from-principal to-secondary inline-block text-darkgray group-[.active]:text-transparent group-[.active]:bg-clip-text text-xl fa-solid fa-location-dot"></i>

          <span class="text-sm text-darkgray group-[.active]:text-black">CONTACTO</span>
        </router-link>


      </div>
    </footer>


    <button id="to-top-button" @click="goToTop()"
            class="hidden fixed z-40 bottom-[92px] right-2 flex justify-center items-center border-0 w-12 h-12 rounded-lg shadow-md bg-black text-white text-lg font-semibold transition-colors duration-300 opacity-20">

        <i class="text-sm fa-solid fa-chevron-up"></i>

    </button>


  <div  v-if="iosPrompt == 1 && !this.getCookie('dismissInstallApp')" class="z-50 fixed top-0 left-0 w-screen h-screen bg-black/90 text-white p-6 flex justify-center">

    <div @click="dismiss" class="absolute top-4 right-4" >Continuar en el navegador</div>

    <div class="text-center leading-10 mt-36">
      <img class="w-[100px] h-auto rounded-lg m-auto mb-6" :src="protocolos_semi_msg">

      <div class="text-[20px]">
        <span>Para instalar nuestra app</span>
        <br>
        <span>pulsa en <img :src="box_arrow_up" class="-mt-1 inline-block w-6 invert"></span>
        <br>
        <span> y elige la opción<br>"Añadir a pantalla de inicio"</span>
      </div>
    </div>

    <i class="text-[50px] fa-solid fa-arrow-down font-sm fixed bottom-4"></i>

  </div>

</template>

<script>
  import protocolos_semi  from './assets/protocolos_semi.png'
  import protocolos_semi_msg  from './assets/protocolos_semi_msg.png'
  import box_arrow_up  from './assets/box-arrow-up.png'

  import update from './mixins/update'


  export default {
    name: 'AppVue',

    data() {
      return {
        protocolos_semi, protocolos_semi_msg, box_arrow_up,
        notifications: 0,
        deferredPrompt: null,
        iosPrompt: null
      }
    },
    mixins: [update],
    created(){
        window.addEventListener("beforeinstallprompt", e => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
        });
        window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });

      // Detects if device is on iOS
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.deferredPrompt = 1;
      }

    },
    mounted() {
      this.getLastProtocol();
      window.addEventListener('scroll', this.handleScroll);

    },

    methods: {
      async dismiss() {
        this.deferredPrompt = null;
        this.iosPrompt = null;
        this.setCookie('dismissInstallApp', 1, 7 );
      },
      async install() {
        if(this.deferredPrompt == 1){
          this.iosPrompt = 1;
        }else{
          this.deferredPrompt.prompt();
        }
      },
      getLastProtocol(){
        fetch('https://api.protocolos.fesemi.org/api/recientes')
          .then((response) => response.json())
          .then((data) => {
            if(this.getCookie('last_protocol') != data[0].slug){
              this.notifications = 1;
            }
          })
          .catch(err => {
            console.log(err)
          });
      },
      setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
       getCookie(cname) {
          let name = cname + "=";
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        },
        handleScroll(){
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                document.getElementById("to-top-button").classList.remove("hidden");
            } else {
                document.getElementById("to-top-button").classList.add("hidden");
            }
        },
        goToTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

  }

</script>