import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css'; //css tailwind
import './styles/fontawesome.min.css'; //css fontawesome
import {createRouter, createWebHashHistory } from "vue-router";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker';

/* import specific icons */
import { faHouse } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faHouse);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import('./components/PaginaInicio.vue')
    },
    {
        path: '/protocolos/:slug',
        name: 'Listado protocolos',
        component: () =>
            import('./components/ListadoProtocolos.vue'),
    },
    {
        path: '/protocolo/:slug',
        name: 'Protocolo',
        component: () =>
            import('./components/Protocolo.vue'),
    }
    ,
    {
        path: '/busqueda',
        name: 'Busqueda',
        component: () =>
            import('./components/Busqueda.vue'),
    },
    {
        path: '/recientes',
        name: 'Recientes',
        component: () =>
            import('./components/Recientes.vue'),
    },
    {
        path: '/contacto',
        name: 'Contacto',
        component: () =>
            import('./components/Contacto.vue'),
    },
    {
        path: '/pdf/:slug',
        name: 'PDF',
        component: () =>
            import('./components/PdfView.vue'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = "Algoritmos y Protocolos | SEMI";
    next();
});

createApp(App)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then(function() {
            // console.log('Service Worker registered with scope:', registration.scope);
        }).catch(function(error) {
        console.log('Service Worker registration failed:', error);
    });
}



